import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Bowser from 'bowser';
import isMobile from 'ismobilejs';
import FaceCakeTryon from '@facecake/javascript-tryon';
import { /* DrawFacePoints, FacePointsProduct, */ FaceMeshTracker } from '@facecake/javascript-tryon';
import { navigate } from '@reach/router';

const RealtimeTryon = forwardRef(({ videoSource, compare, drawType, selectedProduct, onWebcamFailed, flipVideo, followUser, showPerfMonitor, compareProductIds }, ref) => {

    let Tracker = useRef(null);
    let videoSourceRef = useRef(videoSource);
    let compareRef = useRef(compare || { state: 'off', size: { width: 640, height: 480 } });
    let drawTypeRef = useRef(drawType);
    let selectedProductRef = useRef(selectedProduct);
    let selectedVideoRef = useRef(1);
    // let facePointsVisible = useRef(false);
    const snapshotRef = useRef();
    let noCompareWidth = 480,
        noCompareHeight = 480;
    if (isMobile().phone) {
      noCompareWidth = 480;
      noCompareHeight = 640;
    }
    if (window.innerWidth >= 960) {
      noCompareWidth = 640;
    }


   // destroy on page unload
    useEffect(() => {
      return function unMountCleanUp() {
        Tracker.current.destroy();
      }
    }, []);

    useEffect(() => {
        // console.log(initialCamLocation);
        // console.log(initialCamLocation.current);
        // instantiate Tracker
        if (Tracker.current === null) {
            /*
            Tracker.current = new FaceCakeTryon("tracker-div", new FaceMeshTracker(), {
                constraints: {
                    video: {
                        width: 640,
                        height: 480,
                        // resizeMode: 'crop-and-scale',
                    },
                },
                videoWidth: 480,
                videoHeight: 480,
                flipVideo: flipVideo,
                followUser: followUser,
                showPerfMonitor: showPerfMonitor,
                videoSource: videoSource,
            });
            */
            // loading spinner
            const loadingElement = document.createElement('div');
            loadingElement.id = 'circleG';
            loadingElement.innerHTML = `
              <div id="circleG_1" class="circleG"></div>
              <div id="circleG_2" class="circleG"></div>
              <div id="circleG_3" class="circleG"></div>
            `;
            const userOS = Bowser.parse(window.navigator.userAgent)?.os?.name?.toLowerCase();
            const params = new URLSearchParams(window.location.search);
            let forceTracker;
            if (params.has('force-tracker')) {
              forceTracker = params.get('force-tracker').toLowerCase();
              console.warn(`Forcing ${forceTracker} tracker.`);
            }
            // force tracker if flagged, else default to FaceMesh tracker
            let FaceTracker = new FaceMeshTracker();
            Tracker.current = new FaceCakeTryon('tracker-div', FaceTracker, {
              constraints: {
                video: {
                  width: noCompareWidth,
                  height: noCompareHeight,
                  // resizeMode: 'crop-and-scale',
                  aspectRatio: { exact: isMobile().phone ? 4/3 : noCompareWidth / noCompareHeight },
                },
              },
              videoWidth: noCompareWidth,
              videoHeight: noCompareHeight,
              flipVideo: flipVideo,
              followUser: followUser,
              showPerfMonitor: showPerfMonitor,
              videoSource: videoSource,
              loadingElement: loadingElement,
            });
            Tracker.current.onReady(() => {
              // hide initial video canvas on load if starts with compare
              if (compareProductIds?.length) {
                document.querySelector('#tracker-div').classList.add('hide');
              }
              initTryon();
            });
            Tracker.current.setOnGetUserMediaFailedCallback(() => {
              window.parent.postMessage(
                { action: 'cakeAppEvent', event: 'load camera error' },
                '*'
              );
              let cameraErrorMsg = 'Camera error';
              const params = new URLSearchParams(window.location.search);
              if (params.has('cameraErrorMsg')) {
                cameraErrorMsg = params.get('cameraErrorMsg');
              }
              navigate(`/error?errorMsg=${encodeURIComponent(cameraErrorMsg)}`);
            });

            function initTryon() {
              // if 1 compareProductIds is passed, call 2x compare
              if (compareProductIds?.length === 1) {
                window.postMessage({
                  action: 'compare2x',
                  compareProductIds: compareProductIds,
                });
              }
              // if 3 compareProductIds are passed, call 4x compare
              if (compareProductIds?.length === 3) {
                window.postMessage({
                  action: 'compare4x',
                  compareProductIds: compareProductIds,
                });
              }
              //Tracker.current.setOnGetUserMediaCallback(() => {
              // this callback fires after WebCam has been allowed & successful
              //});

              //              Tracker.current.setOnInitializedCallback(() => {
              // add product
              // Tracker.current.addVideoCanvas(document.getElementById('alter-image')).addProduct(drawType.type, drawType.drawFunction, { ...selectedProduct });
              Tracker.current
                .addVideoCanvas()
                .addProduct(drawType.type, drawType.drawFunction, {
                  ...selectedProduct,
                });
              //})

              if (onWebcamFailed !== 'undefined') {
                // this callback fires after the webcam has been blocked or failed.
                Tracker.current.setOnGetUserMediaFailedCallback(() => {
                  onWebcamFailed(); // prop
                });
              }

              // // add product
              // Tracker.current.addProduct(drawType.type, drawType.drawFunction, selectedProduct);
              // show facePoints when pressing the "p" key if DEV environment
              /* if (isDev()) {
                window.document.onkeypress = function (e) {
                  e = e || window.event;
                  if (e.keyCode === 112) {
                      if (facePointsVisible.current === true) {
                          Tracker.current.getVideoCanvas(0).removeProduct('facepoints');
                          facePointsVisible.current = false;
                      } else {
                          Tracker.current.getVideoCanvas(0).addProduct('facepoints', DrawFacePoints, new FacePointsProduct({ productId: 22, fillColor: 0x00FF00 }));
                          facePointsVisible.current = true;

                      }
                  }
                };
              }*/
              // sent page loaded event to parent
              window.parent.postMessage(
                { action: 'cakeAppEvent', event: 'camera loaded' },
                '*'
              );
            }

            // this function fires when any canvases are clicked
            // Tracker.current.onVideoCanvasEventListener((selectedCanvas, e) => {
            //     selectedVideoRef.current = selectedCanvas;
            // });

        }

        // add event listener for postMessage()
        window.addEventListener('message', (event) => {
          switch (event.data?.action) {
            case 'setCompare':
              changeCompare(event.data?.compareState);
              break;
            default:
              break;
          }
        });

        const changeCompare = ({ state, size, compareProducts }) => {
          const { width, height } = size;

          const numCanvasesLimit = {
            addAlterImage: 2,
            off: 1,
            compare2x: 2,
            compare4x: 4,
          };

          let numCanvases = Tracker.current.getVideoCanvases().length;

          // remove all canvases if turning compare on or off
          if (
            (numCanvases === 4 && state === 'off') ||
            (numCanvases === 2 && state === 'off') ||
            (numCanvases === 1 && state === 'compare2x') ||
            (numCanvases === 1 && state === 'compare4x')
          ) {
            // get the current left product
            const currentLeftProduct = Tracker.current
              .getVideoCanvas(0)
              .getProduct(drawType.type).currentProduct;

            //remove all canvases
            for (let i = numCanvases - 1; i >= 0; i--) {
              Tracker.current.removeVideoCanvas(i);
            }
            // reset canvas sizes
            Tracker.current.resizeVideo(width, height);
            // get current number of canvases (it should be 1.)
            numCanvases = Tracker.current.getVideoCanvases().length;

            // add the current left product canvas
            Tracker.current
              .addVideoCanvas()
              .addProduct(drawType.type, drawType.drawFunction, {
                ...currentLeftProduct,
              });

            // add additional compare product canvases
            for (
              let i = 0;
              i < numCanvasesLimit[state] && i < compareProducts?.length;
              i++
            ) {
              Tracker.current
                .addVideoCanvas()
                .addProduct(drawType.type, drawType.drawFunction, {
                  ...compareProducts[i],
                });
            }
            // reset selected canvas to 0
            Tracker.current.selectVideoCanvas(1);
            selectedVideoRef.current = 1;

            // add compare-2x class to tracker-div if compare 2x
            if (state === 'compare2x') {
              document
                .querySelector('#tracker-div')
                .classList.remove('no-compare');
              document
                .querySelector('#tracker-div')
                .classList.remove('compare-4x');
              document
                .querySelector('#tracker-div')
                .classList.add('compare-2x');
            // add compare-4x class to tracker-div if compare 4x
            } else if (state === 'compare4x') {
              document
                .querySelector('#tracker-div')
                .classList.remove('no-compare');
              document
                .querySelector('#tracker-div')
                .classList.remove('compare-2x');
              document
                .querySelector('#tracker-div')
                .classList.add('compare-4x');
            // else clear it out
            } else {
              document
                .querySelector('#tracker-div')
                .classList.remove('compare-2x');
              document
                .querySelector('#tracker-div')
                .classList.remove('compare-4x');
              document
                .querySelector('#tracker-div')
                .classList.add('no-compare');
            }
          } else if (state === 'compare2x') {
            Tracker.current
              .getVideoCanvas(1)
              .getProduct(drawType.type).currentProduct = {
              ...compareProducts[0],
            };
          } else if (state === 'compare4x') {
            Tracker.current
              .getVideoCanvas(1)
              .getProduct(drawType.type).currentProduct = {
              ...compareProducts[0],
            };
          }
          // unhide inital video canvas
          document.querySelector('#tracker-div').classList.remove('hide');
        }

        if (videoSource !== videoSourceRef.current) {
            // change videoSource here.
            if(videoSource) {
                Tracker.current.initVideo(videoSource);
            } else {
                Tracker.current.initWebcam();
            }

            videoSourceRef.current = videoSource;
        }

        // compare prop changed
        if (compare !== compareRef.current) {
            changeCompare(compare);
            compareRef.current = compare;
        }

        // drawType prop changed
        if (drawType !== drawTypeRef.current) {

            Tracker.current.getVideoCanvases().forEach(videoCanvas => {
                videoCanvas.removeProducts();
                videoCanvas.addProduct(drawType.type, drawType.drawFunction, selectedProduct);
            });

            drawTypeRef.current = drawType;
            selectedProductRef.current = selectedProduct;
        }

        // selectedProduct prop changed
        if (selectedProduct !== selectedProductRef.current) {
            // Tracker.current.getVideoCanvas(0).getProduct(drawType.type).currentProduct = { ...selectedProduct };
            if (Tracker.current.getVideoCanvases().length === 2) {
              selectedVideoRef.current = 0;
            } else {
              selectedVideoRef.current = 0;
            }
            Tracker.current.getVideoCanvas(selectedVideoRef.current).getProduct(drawType.type).currentProduct = { ...selectedProduct };
            selectedProductRef.current = selectedProduct;
        }
    }, [Tracker, videoSource, compare, drawType, selectedProduct, onWebcamFailed, flipVideo, followUser, showPerfMonitor]);

    // expose 'getSnapShot()` method to parent component. (and return snapshot image data)
    // expose 'getSelectedCanvas() method to parent component.
    useImperativeHandle(ref, () => ({
      getSnapshot: (index) => {
        return new Promise((resolve, reject) => {
          Tracker.current.getSnapshot(index, (imgData) => {
            if (imgData) resolve(imgData);
            else reject();
          });
        });
      },
      startRecording: (index, onStopRecording) => {
        Tracker.current.startRecording(index, onStopRecording);
      },
      stopRecording: (index) => {
        Tracker.current.stopRecording(index);
      },
      getSelectedCanvas: () => selectedVideoRef.current,
    }));


    return (
        <div id="tracker-div" style={{ cursor: 'pointer' }} ref={snapshotRef}></div>
    );

});

// RealtimeTryon.propTypes = {
//     compare: PropTypes.shape({
//         state: PropTypes.oneOf(['off', f2x', 'compare4x']),
//         size: PropTypes.shape({
//             width: PropTypes.number,
//             height: PropTypes.number,
//         })
//     }),
//     drawType: PropTypes.shape({
//         type: PropTypes.string,
//         drawFunction: PropTypes.func,
//     }).isRequired,
//     selectedProduct: PropTypes.object.isRequired,
//     onWebcamFailed: PropTypes.func,
//     flipVideo: PropTypes.bool,
//     followUser: PropTypes.bool,
//     showPerfMonitor: PropTypes.bool
// }
let noCompareWidth = 480, noCompareHeight = 480;
if (isMobile().phone) {
  noCompareWidth = 480;
  noCompareHeight = 640;
}
if (window.innerWidth >= 960) {
  noCompareWidth = 640;
}

RealtimeTryon.defaultProps = {
  compare: {
    state: 'off',
    size: { width: noCompareWidth, height: noCompareHeight },
  },
  flipVideo: true,
  followUser: false,
  showPerfMonitor: false,
};

export default React.memo(RealtimeTryon, () => false);