import React from 'react';
import './assets/scss/styles.scss';
import './assets/fontello/css/fs.css';
import { Router } from "@reach/router";
import TryOnPage from './pages/TryOnPage';
import ErrorPage from './pages/ErrorPage';
import track from 'react-tracking';
import FmtActivityTracker from '@facecake/activity-tracking';

// TODO: get setup from https://cdn.facecake.com/apps/cake.facecake.com/config.json
// setup FMT and Google Analytics activity trackers
const activityTracker = new FmtActivityTracker({
  debug: false,
  appId: 1677,
  apiKey: '79583398ea6646d9ad7a58ffae5a6c47',
  sharedSecret: '04941e47e7e84368',
  gaAccountIds: [],
});

const App = (props) => {
  const trackEvent = props.tracking.trackEvent;

  if (window.location.pathname.indexOf('facecake-chanel-integration-demo-inline') > 0) {
    console.log('try-on path');
    return (
      <Router basepath="/" className="main-wrapper">
        <TryOnPage path="/try-on/:productId" default/>
      </Router>
    );
  }

  // TODO: get JSON with approved origins from https://cdn.facecake.com/apps/cake.facecake.com/config.json,
  // TODO: bypassing for now
  // only show if parent is from allowed origins
  if (true) {
    return (
      <>
        <Router basepath="/" className="main-wrapper">
          <TryOnPage path="/try-on/:partner/:sku" />
          {/* FIXME: partner2 not implemented yet */}
          <TryOnPage path="/try-on/compare/:partner/:sku/:partner2/:sku2" />
          <TryOnPage path="/try-on/compare/:partner/:sku/:partner2/:sku2/:partner3/:sku3/:partner4/:sku4" />
          <ErrorPage path="/error" />
        </Router>
      </>
    );
  // else if not referred from allowed origin then show error page
  } else {
    let generalErrorMsg = 'DIRECT LINKING NOT ALLOWED';
    const params = new URLSearchParams(window.location.search);
    if (params.has('generalErrorMsg')) {
      generalErrorMsg = params.get('generalErrorMsg');
    }

    return <ErrorPage errorMsg={generalErrorMsg} />;
  }
};

export default track({},{dispatch: activityTracker.dispatcher})(App);

