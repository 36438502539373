import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ManageScroll from './components/ManageScroll';

// change page language if set in URL
const params = new URLSearchParams(window.location.search);
if (params.has('lang')) {
  document.documentElement.setAttribute('lang', params.get('lang'));
}
// change page title if set in URL
if (params.has('title')) {
  document.title = params.get('title');
}


if (window.location.pathname === '/example.html') {
  console.log('try-on not loaded');
} else {
  ReactDOM.render(<><ManageScroll/><App/></>, document.getElementById('root'));
}

// load Try-On to #faceCakeDiv to load inline
window.loadTryOn = () => {
  if (document.querySelector('#faceCakeDiv')) {
    console.log('try-on loading inline');
    ReactDOM.render(<><ManageScroll/><App/></>, document.getElementById('faceCakeDiv'));  
  }
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
