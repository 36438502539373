import React, { useEffect, useState, useCallback, useRef } from 'react';
import isMobile from 'ismobilejs';
import track, { useTracking } from 'react-tracking';
import axios from 'axios';
import { setup } from 'axios-cache-adapter';

// import VideoRecorder from 'react-video-recorder';
// import renderActions from '../defaults/render-actions';
import RealtimeTryon from '../components/RealtimeTryon';

// DELETEME: ???
import FaceCakeTryon, {
  FaceMeshTracker,
  // DrawFacePoints,
  DrawThreeGlasses,
} from '@facecake/javascript-tryon';

// import facePoints from '../products/facePoints'; 
import { navigate } from '@reach/router';

// XXX: handle disabled cache
let cdnBaseURL = 'https://cdn.facecake.com';
let axiosCache;
// assign axiosCache to regular axios if disable-cache passed as URL param
const params = new URLSearchParams(window.location.search);
if (params.has('disable-cache')) {
  console.log('URL search param "disable-cache" set. Browser caching disabled.');
  axiosCache = axios;
  // and use non-caching URL
  cdnBaseURL = 'https://cdn-nocache.facecake.com';
} else {
  // else create `axios` instance with pre-configured `axios-cache-adapter` attached to it
  axiosCache = setup({
    cache: {
      maxAge: 15 * 60 * 1000,
    },
  });
}
// XXX: only initialize if init-only is passed as URL param
let initOnly = false;
if (params.has('init-only')) {
  initOnly = true;
  console.log('URL search param "init-only" set.')
}
let noCompareWidth = 480, noCompareHeight = 480;
if (isMobile().phone) {
  noCompareWidth = 480;
  noCompareHeight = 640;
}
if (window.innerWidth >= 960 ) {
  noCompareWidth = 640;
}

const TryOnPage = (props) => {
  const tracking = useTracking();
  useEffect(() => {
    window.onorientationchange = function () {
      window.location.reload();
    };
  }, []);

  const typeMap = {
    glasses: { type: 'glasses', drawFunction: DrawThreeGlasses },
    // facePoints: { type: 'facePoints', drawFunction: DrawFacePoints },
  };

  // state
  const [showTryOn, setShowTryOn] = useState(initOnly === false);
  const pdpType = 'glasses';
  const [compare, setCompare] = useState({
    state: 'off',
    size: { width: noCompareWidth, height: noCompareHeight },
    compareProducts: null,
  });
  const [selectedType /* setSelectedType */] = useState(typeMap[pdpType]);

  const catchError = (err) => {
    console.error(err);
    // goto error page
    navigate('/error');
  };

  // get product from CDN
  const getProduct = async (partner, sku) => {
    // get the shorter SKU number
    const lowercaseSKU = sku.toLowerCase();
    // let response = await axiosCache.get(`https://cdn.facecake.com/p/${partner}/${lowercaseSKU}/metadata/index.json`);
    // DEBUG: const productMetadata = response.data;
    // DEBUG: console.log(`${partner}/${lowercaseSKU}/metadata:`, productMetadata);
    let response = await axiosCache.get(
      `${cdnBaseURL}/p/${partner}/${lowercaseSKU}/metadata/web.json`
    );
    const product = response.data;
    if (response?.data?.gltfURL) {
      product.gltfURL = response.data.gltfURL.replace(
        'https://cdn.facecake.com',
        cdnBaseURL
      );
    }
    // DEBUG: console.log(`${partner}/${lowercaseSKU}/web.json:`, product);
    return product;
  };

  // get multiple products from CDN
  const getProducts = async (partner, productIds) => {
    let products = [];
    for (const productId of productIds) {
      const thisProduct = await getProduct(partner, productId);
      products.push(thisProduct);
    }
    return products;
  };

  useEffect(() => {
    getProduct(props.partner, props.sku)
      .then((product) => {
        setSelectedProduct(product);
      })
      .catch(catchError);

    // DELETEME: ???
    // initialize on page load to dramatically improve load speed
    // eslint-disable-next-line no-unused-vars
    let initFaceCakeTryon;
    if (initOnly) {
      initFaceCakeTryon = new FaceCakeTryon(null, new FaceMeshTracker(), {
        initOnly: true,
      });
    }

    // handle posted messages from parent window
    window.addEventListener(
      'message',
      (event) => {
        switch (event.data?.action) {
          case 'showTryOn':
            setShowTryOn(true);
            break;
          case 'hideTryOn':
            setShowTryOn(false);
            break;
          case 'selectProduct':
            {
              let { productId, collection } = event.data;
              getProduct(collection || props.partner, productId)
                .then((product) => {
                  // HACK: FIXME:replace productId if 0 or -1
                  if (['0', '-1'].includes(product.productId)) {
                    const sku = event.data?.productId;
                    // strip non-numeric chars and make temp productId the biggest MSSQL int
                    product.productId = parseInt(sku.replace(/\D/g, '')) ^ Math.pow(2, 31);
                  }

                  setSelectedProduct(product);
                })
                .catch(catchError);
            }
            break;
          case 'getSnapshot':
            getSnapshot(0);
            break;
          case 'startRecording':
            const onStopRecording = (videoBlob) => {
              window.parent.postMessage(
                { action: 'recording', videoData: videoBlob },
                '*'
              );
            };
            startRecording(0, onStopRecording);
            console.log('start video recording');
            break;
          case 'stopAndGetRecording':
            console.log('stop video recording');
            stopRecording(0);
            break;
          case 'compare2x':
            let compareProducts;
            let compareWidth = 480;
            let compareHeight = 480;
            if (window.innerWidth >= 960) { compareWidth = 480; }
            if (window.innerWidth >= 480 && window.innerWidth < 960) {
              compareWidth = window.innerWidth / 2;
            }
            if (isMobile().phone) { compareHeight = 640; }
            let { compareProductIds, collection } = event.data;
            getProducts(collection || props.partner, compareProductIds)
              .then((products, err) => {
                compareProducts = products;
                // HACK: FIXME:replace productId if 0 or -1
                products = products.map((product, index) => {
                  let { productId } = product;
                  if (["0", "-1"].includes(productId)) {
                    const sku = event.data?.compareProductIds[index];
                    // strip non-numeric chars and make temp productId the biggest MSSQL int
                    product.productId = parseInt(sku.replace(/\D/g,'')) ^ (Math.pow(2,31))
                  }
                  return product;
                })
                window.postMessage(
                  {
                    action: 'setCompare',
                    compareState: {
                      state: 'compare2x',
                      size: {
                        width: compareWidth,
                        height: compareHeight,
                      },
                      compareProducts: compareProducts,
                    },
                  },
                  '*'
                );
              })
              .catch(catchError);
            break;
          case 'compare4x':
            {
              let compareProducts;
              let compareWidth = 480;
              let compareHeight = 480;
              if (window.innerWidth >= 960) {
                compareWidth = 480;
              }
              
              let { compareProductIds, collection } = event.data;
              getProducts(collection || props.partner, compareProductIds)
                .then((products, err) => {
                  compareProducts = products;
                  // HACK: FIXME:replace productId if 0 or -1
                  products = products.map((product, index) => {
                    let { productId } = product;
                    if (['0', '-1'].includes(productId)) {
                      const sku = event.data?.compareProductIds[index];
                      // strip non-numeric chars and make temp productId the biggest MSSQL int
                      product.productId =
                        parseInt(sku.replace(/\D/g, '')) ^ Math.pow(2, 31);
                    }
                    return product;
                  });
                  window.postMessage(
                    {
                      action: 'setCompare',
                      compareState: {
                        state: 'compare4x',
                        size: {
                          width: compareWidth,
                          height: compareHeight,
                        },
                        compareProducts: compareProducts,
                      },
                    },
                    '*'
                  );
                })
                .catch(catchError);
              break;
            }
          case 'compareOff':
            setCompare({
              state: 'off',
              size: { width: noCompareWidth, height: noCompareHeight },
              compareProductIds: event.data?.compareProductIds,
            });
            window.postMessage(
              {
                action: 'setCompare',
                compareState: {
                  state: 'off',
                  size: { width: noCompareWidth, height: noCompareHeight },
                  compareProductIds: event.data?.compareProductIds,
                },
              },
              '*'
            );
            break;
          default:
            break;
        }
      },
      false
    );

    return () => {
      // DELETEME: ???
      initFaceCakeTryon = null;
    };
  }, [props.sku]);

  const prefix = window.location.hostname.split('.')[0];
  const filename = `${prefix}-client-video-converted.mp4`;
  const [videoSource, setVideoSource] = useState(
    props.alterImageVideo
      ? `https://api1.facecake.com/apps/ces2020/videos/${filename}?ver=${Math.random()}`
      : false
  );
  // const [alterImageClicked, setAlterImageClicked] = useState(false);
  const [showVideoRecorder, setShowVideoRecorder] = useState(false);
  const [alterImageVideo, setAlterImageVideo] = useState(
    props.alterImageVideo ? 'video' : 'webcam'
  );

  const [selectedProduct, setSelectedProduct] = useState();
  const [showRealtimeTryon, setShowRealtimeTryon] = useState(
    alterImageVideo === 'video'
  );

  const realtimeTryonRef = useRef(null);
  const [snapShotImageData, setSnapShotImageData] = useState(null);

  /* const getVideoElem = useCallback(
        () => realtimeTryonRef.current.getVideoElem(), [realtimeTryonRef]
    ) */
  // function to get currently selected canvas
  const getSelectedCanvas = useCallback(
    () => realtimeTryonRef.current.getSelectedCanvas(),
    [realtimeTryonRef]
  );

  // function to get snapShot
  const getSnapshot = useCallback(
    (index) => {
      // if index is set (even if it's zero), then use that; otherwise call getSelectCanvas()
      const selectedCanvas = index || index === 0 ? index : getSelectedCanvas();
      realtimeTryonRef.current.getSnapshot(selectedCanvas).then((imgData) => {
        setSnapShotImageData(imgData);
        // post message to parent window with image data
        window.parent.postMessage(
          { action: 'snapshot', imageData: imgData },
          '*'
        );
        console.log('getSnapshot(): imgData posted to parent ');
      });
    },
    [realtimeTryonRef, getSelectedCanvas]
  );
  // function to start recording
  const startRecording = useCallback(
    (index, onStopRecording) => {
      // if index is set (even if it's zero), then use that; otherwise call getSelectCanvas()
      const selectedCanvas = index || index === 0 ? index : getSelectedCanvas();
      if (realtimeTryonRef.current) {
        realtimeTryonRef.current.startRecording(selectedCanvas, onStopRecording);
      }
    },
    [realtimeTryonRef, getSelectedCanvas]
  );

  // function to stop recording
  const stopRecording = useCallback(
    (index, onStopRecording) => {
      // if index is set (even if it's zero), then use that; otherwise call getSelectCanvas()
      const selectedCanvas = index || index === 0 ? index : getSelectedCanvas();
      realtimeTryonRef.current.stopRecording(selectedCanvas);
    },
    [realtimeTryonRef, getSelectedCanvas]
  );

  /* const activateTryon = () => {
        tracking.trackEvent({ action: 'Select Try On', appliedAssetId: selectedProduct.productId });
        if (!showRealtimeTryon) {
            setShowRealtimeTryon(false);
        } else {
            setAlterImageVideo('webcam');
            setVideoSource(false);
        }
    } */

  const handleRecordingComplete = (blob) => {
    const serverUrl = 'https://api1.facecake.com/apps/ces2020/saveVideo.php';
    const prefix = window.location.hostname.split('.')[0];
    const filename = `${prefix}-client-video.webm`;
    const formData = new FormData();
    formData.append('file', blob, filename);

    console.log('uploading: ' + filename + ' to ' + serverUrl);

    fetch(serverUrl, {
      method: 'POST',
      body: formData,
    })
      .then((success) => {
        console.log(success, 'video uploaded and converted to mp4. Success.');
      })
      .catch((error) => console.error(error, ' an upload error occurred!'));
  };

  const handleStopPlaying = () => {
    console.log('done playing');

    setShowVideoRecorder(false);
    const currentUrl = window.location.href;
    const prefix = window.location.hostname.split('.')[0];
    const filename = `${prefix}-client-video-converted.mp4`;
    if (currentUrl.match(/\/?alterImageCompleted$/)) {
      window.location.href = currentUrl;
      setVideoSource(
        `https://api1.facecake.com/apps/ces2020/videos/${filename}?ver=${Math.random()}`
      );
      setAlterImageVideo('video');
    } else {
      if (window.location.href.match(/\/$/)) {
        window.location.href = currentUrl + 'alterImageCompleted';
      } else {
        window.location.href = currentUrl + '/alterImageCompleted';
      }
      setVideoSource(
        `https://api1.facecake.com/apps/ces2020/videos/${filename}?ver=${Math.random()}`
      );
      setAlterImageVideo('video');
    }
  };

  if (selectedProduct) {
    let compareProductIDs;
    if(props?.sku2) { compareProductIDs = [props.sku2]; }
    if(props?.sku4) { compareProductIDs = [props.sku2, props.sku3, props.sku4]; }
    return (
      <div className="pdp-wrapper">
        <div className="pdp-container">
          {showTryOn && (
            <RealtimeTryon
              videoSource={videoSource}
              drawType={selectedType}
              selectedProduct={selectedProduct}
              compareProductIds={compareProductIDs}
              onWebcamFailed={() => {
                setShowRealtimeTryon(false);
              }}
              ref={realtimeTryonRef}
            />
          )}

          {/*<div className="products-wrapper">
                                  {alterImageVideo === 'webcam' || alterImageClicked ? (
                                      props.productId !== '1216274' && (
                                          <Compare<
                                              pdpType={pdpType}
                                              compare={compare}
                                              setCompare={(compare) => {
                                                tracking.trackEvent({ action: compare.state === 'off' ? 'Close Compare' :'Select Compare', actionDescriptor: compare.state });
                                                setCompare(compare);
                                              }}
                                          />
                                      )
                                  ) : 
                                    <span> </span>
                                  }
                                </div>*/}
          {/* {showRealtimeTryon ? (
              <div
                className='video-recorder'
                style={
                  showVideoRecorder ? { display: 'block' } : { display: 'none' }
                }
              >
                <VideoRecorder
                  isOnInitially
                  timeLimit={4000}
                  countdownTime={4}
                  onRecordingComplete={handleRecordingComplete}
                  constraints={{ audio: false, video: true }}
                  renderActions={renderActions}
                  onStopReplaying={handleStopPlaying}
                  mimeType='video/webm;codecs=h264'
                />
              </div>
            ) : null} */}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

TryOnPage.defaultProps = {
  productId: null,
}

export default track({
  page: 'PDP Page'},
  {dispatchOnMount: data => ({action: 'Viewed Page', actionDescriptor: `PDP Page ${window.location.pathname}`}),
})(TryOnPage);