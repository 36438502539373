import React, { Component } from 'react';
import track from 'react-tracking';

class ErrorPage extends Component {
  constructor(props) {
    super(props);
    this.errorMsg = this.props?.errorMsg || 'ERROR LOADING TRY-ON';
  }

  render() {
    // change errorMsg  if set in URL
    const params = new URLSearchParams(window.location.search);
    if (params.has('errorMsg')) {
      this.errorMsg = params.get('errorMsg');
    }

    return (
      <div className="pdp-wrapper"
        style={{margin: 'auto', width: '50%', height: '100vh'}}
      >
        <div style={{position: 'absolute', top: '30%', width: '50%', textAlign: 'center'}}>
          {this.errorMsg}
        </div>
      </div>
    )
  }
}

export default track({
  page: 'Error Page'},
  {dispatchOnMount: data => ({action: 'Viewed Page', actionDescriptor: 'Error Page'}),
})(ErrorPage);